import React from 'react'

import { Flex, FlexProps } from '@plco-pro/components/atoms/flex'
import { Spinner } from '@plco-pro/components/atoms/spinner'

const Container = (props: FlexProps) => (
  <Flex sx={{ justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }} {...props} />
)

const Home = () => {
  return (
    <Container>
      <Spinner />
    </Container>
  )
}

export default Home
